.header {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 25%,
    rgba(0, 0, 0, 0.6) 60%,
    rgba(0, 0, 0, 0)
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: fixed;
  width: 100%;
  height: 100px;
  z-index: 10;
  transition: 0.5s;
}

.header h1 {
  width: 18rem;
  height: auto;
  display: block;
  margin: 0 auto;
}

.header-bg {
  height: 70px;
  transition: 0.5s;
}

.nav-menu {
  display: flex;
}

.nav-menu li {
  padding: 0 1rem;
}

.nav-menu li a {
  font-size: 1.2rem;
  font-weight: 500;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 1000px) {
  .nav-menu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -3;
    transition: 0.3s;
  }

  .header h1 {
    width: 14rem;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-menu li {
    padding: 1rem 0;
  }

  .nav-menu li a {
    font-size: 2rem;
  }

  .hamburger {
    display: initial;
  }
}
