.footer > .content > .created {
  font-size: 1rem;
  font-weight: 200;
  margin: 0.5rem;
  text-align: center;
  white-space: nowrap;
}

.footer > .content > .copyright {
  font-size: 1rem;
  font-weight: 200;
  margin: 0 0 1rem 0;
  text-align: center;
}

.footer > .content > .created > .logo {
  max-height: 1em;
  vertical-align: middle;
}

@media screen and (max-width: 1000px) {
  .footer > .content > .created {
    font-size: 1.15rem;
  }

  .footer > .content > .copyright {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 720px) {
  .footer > .content > .created {
    font-size: 0.8rem;
  }

  .footer > .content > .copyright {
    font-size: 0.8rem;
  }
}
