@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}

body {
  background: #000;
}

.home-background {
  width: 100%;
  height: 100vh;
  position: relative;
}

.home-background > .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-background::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.7;
}

.home-background::before {
  content: "";
  width: 100%;
  height: 20%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 1) 1%, rgba(0, 0, 0, 0));
  background-size: cover;
}

.section-background {
  position: absolute; /* Position the background absolutely within the container */
  top: 0;
  left: 0;
  width: 100%; /* Make the background cover the entire container */
  height: 100%;
  z-index: -1; /* Place the background behind the content */
}

.section-background::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 0.9) 15%,
    rgba(0, 0, 0, 0.35) 50%,
    rgba(0, 0, 0, 0)
  );
  background-size: cover;
}

.section-background::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 0.9) 15%,
    rgba(0, 0, 0, 0.35) 50%,
    rgba(0, 0, 0, 0)
  );
  background-size: cover;
}

.section-background > .image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the background image covers the container */
}

.gradient-container {
  display: flex;
  justify-content: center; /* Aligns content horizontally at the center */
}

.gradient-line {
  width: 100%;
  height: 3px;
  max-width: 20rem;
  background: linear-gradient(
    to right,
    transparent,
    rgb(248, 217, 15),
    transparent
  );
}

h1 {
  color: #fff;
  text-decoration: none;
}

p {
  color: #fff;
  text-decoration: none;
}

a {
  color: #fff;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.button {
  background: rgb(248, 217, 15);
  color: #222;
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
  margin: 0.5rem;
}

.button-light {
  background: transparent;
  color: #fff;
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
  margin: 0.5rem;
}

.button:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}

.button-light:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}
