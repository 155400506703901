.projects {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.projects > .content h1 {
  font-size: 3.5rem;
  padding: 5rem 0 0.5rem;
  text-align: center;
}

.projects > .content p {
  font-size: 1.4rem;
  font-weight: 200;
  padding: 0.5rem 0 2.2rem;
  text-transform: uppercase;
  text-align: center;
}

.project-card-container {
  margin: 0 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  padding: 2rem;
}

.project-card {
  background: #1a1919;
  padding: 1.2rem 1rem;
  padding-bottom: 6rem;
  text-align: center;
  position: relative;
}

.project-card img {
  width: 100%;
}

.project-card-title {
  color: #fff;
  padding: 1rem 0 0;
  font-size: 2rem;
  font-weight: 800;
}

.project-card-text {
  color: #fff;
  padding: 1rem 0 1rem;
  font-size: 1.25rem;
  font-weight: 300;
}

.project-card-button {
  background: rgb(248, 217, 15);
  color: #222;
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
  position: absolute;
  margin: 2rem;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

.project-card-button:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}

@media screen and (max-width: 1000px) {
  .project-card-container {
    margin: 0 10rem;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 720px) {
  .projects > .content h1 {
    font-size: 3rem;
  }

  .projects > .content p {
    font-size: 1.2rem;
  }

  .project-card-container {
    margin: 0 1rem;
  }
}
