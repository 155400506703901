.contact {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact > .content h1 {
  font-size: 3.5rem;
  padding: 5rem 0 0.5rem;
  text-align: center;
}

.contact > .content p {
  font-size: 1.4rem;
  font-weight: 200;
  padding: 0.5rem 0 2.2rem;
  text-transform: uppercase;
  text-align: center;
}

.contact-card-container {
  margin: 0 2rem 0 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  padding: 2rem;
}

.contact-card {
  background-color: #1a1919;
  display: flex;
  padding: 0.5rem;
  position: relative;
  align-items: center;
}

.contact-card-img-container {
  height: 4rem;
  width: 4rem;
  margin: 1.5rem;
  align-items: center;
}

.contact-card-img-container img {
  max-width: 100%;
  max-height: 100%;
}

.contact-card-secondary-img-container {
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2rem; /* Adjust the right value as needed */
  align-items: center;
}

.contact-card-secondary-img-container img {
  max-width: 100%;
  max-height: 100%;
}

.contact-card-desc-container {
  display: grid;
  grid-template-rows: 1fr;
  padding: 0 1rem 0;
}

.contact-card-desc-title {
  color: #fff;
  padding: 0.5rem 0 0;
  font-size: 2rem;
  font-weight: 800;
}

.contact-card-desc-text {
  color: #fff;
  padding: 0.5rem 0 0.5rem;
  font-size: 1.25rem;
  font-weight: 300;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s linear;
}

.contact-card-desc-text:hover {
  border-bottom: 2px solid #fff;
}

.social-card-container {
  width: min-content;
  margin: 0 auto 2rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.social-card {
  margin: 1rem;
  align-items: center;
}

.social-card-img-container {
  height: 5rem;
  width: 5rem;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: rgb(248, 217, 15);
}

.social-card-img-container:hover {
  background: rgb(139, 147, 115);
}

.social-card-button {
  max-width: 100%;
  max-height: 100%;
}

.social-card-img-container img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 1000px) {
  .contact-card-container {
    margin: 0;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    padding: 1.5rem;
  }

  .contact-card-img-container {
    height: 3rem;
    width: 3rem;
    margin: 1rem;
  }

  .contact-card-desc-title {
    font-size: 1.5rem;
    font-weight: 800;
  }

  .contact-card-desc-text {
    font-size: 1rem;
    font-weight: 400;
  }

  .social-card-img-container {
    height: 4rem;
    width: 4rem;
  }
}

@media screen and (max-width: 720px) {
  .contact > .content h1 {
    font-size: 3rem;
  }

  .contact > .content p {
    font-size: 1.2rem;
  }

  .social-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
