.about {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about > .content h1 {
  font-size: 3.5rem;
  padding: 5rem 0 0.5rem;
  text-align: center;
}

.about > .content p {
  font-size: 1.4rem;
  font-weight: 200;
  padding: 0.5rem 0 2.2rem;
  text-transform: uppercase;
  text-align: center;
}

.about-container {
  margin: 0 4rem;
  display: flex;
  grid-gap: 40px;
  padding: 2rem;
}

.who-are-we {
  flex: 35%;
  position: relative;
}

.who-are-we-title {
  color: #fff;
  padding: 1rem 0 0;
  font-size: 2rem;
  font-weight: 800;
}

.who-are-we-text {
  color: #fff;
  padding: 1rem 0 1rem;
  font-size: 1.25rem;
  font-weight: 300;
}

.who-are-we-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem;
  margin-right: 4rem;
}

.who-are-we-button-container .button {
  position: absolute;
  width: fit-content;
  text-align: center;
}

.why-choose-us {
  flex: 65%;
}

.why-choose-us-title {
  color: #fff;
  padding: 1rem 0 0;
  font-size: 2rem;
  font-weight: 800;
}

.why-choose-us-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  padding: 1rem;
}

.why-choose-us-card {
  display: flex;
  padding: 0.5rem;
  position: relative;
  align-items: center;
}

.why-choose-us-card-img-container {
  height: 4rem;
  width: 4rem;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: rgb(248, 217, 15);
}

.why-choose-us-card-img-container img {
  max-width: 100%;
  max-height: 100%;
}

.why-choose-us-card-desc-container {
  flex: 80%;
  display: grid;
  grid-template-rows: 1fr;
  padding: 0 1rem 0;
}

.why-choose-us-card-desc-title {
  color: #fff;
  padding: 0.5rem 0 0;
  font-size: 1.5rem;
  font-weight: 800;
}

.why-choose-us-card-desc-text {
  color: #fff;
  padding: 0.5rem 0 0.5rem;
  font-size: 1.25rem;
  font-weight: 300;
}

.technology-card-container {
  width: min-content;
  margin: 0 auto 2rem;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.technology-card {
  margin: 0.5rem;
  align-items: center;
}

.technology-card-img-container {
  height: 9rem;
  width: 9rem;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  border-radius: 10%;
  background-color: #fff;
}

.technology-card-img-container img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 1500px) {
  .technology-card-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1000px) {
  .about-container {
    margin: 0 8rem;
    flex-direction: column;
  }

  .who-are-we {
    flex: 100%;
  }

  .who-are-we-title {
    text-align: center;
  }

  .who-are-we-button-container {
    margin: 0 4rem;
  }

  .why-choose-us-title {
    text-align: center;
  }

  .why-choose-us {
    flex: 100%;
  }

  .technology-card-img-container {
    height: 7.5rem;
    width: 7.5rem;
  }
}

@media screen and (max-width: 720px) {
  .about > .content h1 {
    font-size: 3rem;
  }

  .about > .content p {
    font-size: 1.2rem;
  }

  .about-container {
    margin: auto;
    flex-direction: column;
  }

  .technology-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
