.home > .content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
}

.home > .content h1 {
  width: 25rem;
  height: auto;
  display: block;
  margin: 0 auto;
}

.home > .content p {
  font-size: 1.4rem;
  font-weight: 200;
  padding: 0.5rem 0 2.2rem;
  text-transform: uppercase;
  text-align: center;
}

@media screen and (max-width: 720px) {
  .home > .content h1 {
    width: 22rem;
  }

  .home > .content p {
    font-size: 1.2rem;
  }
}
